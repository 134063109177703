import React, { ReactElement, useState } from 'react';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import useStyles from './style';
import { useTranslation } from 'react-i18next';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { defaultLocationSelector } from 'services/user/selectors';
import { isMobile } from 'utils/helper';

const MyLocationsList: React.FC<{
  favoriteLocations: any;
  selectedLocation: number;
  handleSelect: any;
  setLocationAsDefaultHandler: any;
  handleAddLocation?: (id: number) => void;
  isAdmin: boolean;
}> = ({
  favoriteLocations,
  selectedLocation,
  handleSelect,
  setLocationAsDefaultHandler,
  handleAddLocation,
  isAdmin,
}): ReactElement => {
  const classes = useStyles();

  return (
    <div style={{ paddingLeft: '2px' }}>
      <List className={classes.myLocationList}>
        {favoriteLocations &&
          favoriteLocations.map((item: any, index: any) => {
            return (
              <LocationItem
                key={index}
                handleSelect={handleSelect}
                selectedLocation={selectedLocation}
                index={index}
                setLocationAsDefaultHandler={setLocationAsDefaultHandler}
                item={item}
                handleAddLocation={handleAddLocation}
                isAdmin={isAdmin}
              />
            );
          })}
      </List>
    </div>
  );
};

const LocationItem = ({
  handleSelect,
  selectedLocation,
  index,
  item,
  setLocationAsDefaultHandler,
  handleAddLocation,
  isAdmin,
}: {
  handleSelect: (id: number, index: number) => void;
  selectedLocation: number;
  index: number;
  setLocationAsDefaultHandler: (
    id: number,
    callback?: (res?: any) => void
  ) => void;
  item: {
    id: number;
    name: string;
    count_of_sds?: number;
    is_default?: boolean;
  };
  handleAddLocation?: (id: number) => void;
  isAdmin: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['location_list', 'common']);
  const [loading, setLoading] = useState<boolean>(false);

  const defaultLocationID = useSelector(defaultLocationSelector);

  return (
    <ListItem
      className={classes.listItemRoot}
      button
      selected={String(selectedLocation) == item.id.toString()}
      key={index}
      onClick={() => handleSelect(item.id, index)}
    >
      <Tooltip 
        classes={{ tooltip: classes.tooltip }}
        title={item.name}
        placement="bottom"
        arrow
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: isMobile() ? [0, 0] : [0, 7],
                },
              }
            ],
          },
        }}
      >
        <div className={classes.locationInfo}>
          <ListItemIcon style={{ minWidth: 'auto' }}>
            <CircleOutlinedIcon style={{ fontSize: 10, minWidth: 'auto' }} />
          </ListItemIcon>
          <ListItemText style={{ margin: '0 0 0 10px', marginLeft: -12 }}>
            <span className={classes.locationItemFont}>
              <span className={classes.sdsLabel}>{item.name}</span>
              <span className={classes.countSds}>({item.count_of_sds}) </span>
            </span>
          </ListItemText>
        </div>
      </Tooltip>

      <ListItemIcon
        style={{
          marginLeft: '10px',
          justifyContent: 'flex-end',
          minWidth: 'auto',
          alignItems: 'center',
        }}
      >
        {isAdmin ? (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={`${t('common:add_sub_location')}`}
            placement="bottom"
            arrow
          >
            <AddIcon
              id={'add-sub-location'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (handleAddLocation) {
                  handleAddLocation(item.id);
                }
              }}
              style={{ visibility: 'hidden' }}
            />
          </Tooltip>
        ) : null}
        {loading ? (
          <div>
            <CircularProgress style={{ width: 20, height: 20 }} />
          </div>
        ) : item.id == defaultLocationID ? (
          <StarOutlinedIcon
            style={{
              fill: 'rgb(255, 189, 13)',
              fontSize: 18,
              visibility: `visible`,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              // if (!item.is_default) {
              //   setLoading(true);
              //   setLocationAsDefaultHandler(item.id);
              // }
            }}
          />
        ) : (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={`${t('location_list:set_as_default')}`}
            placement="bottom"
            arrow
          >
            <StarOutlinedIcon
              className={classes.locationNotDefaultIcon}
              style={{ fontSize: 18 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setLoading(true);
                setLocationAsDefaultHandler(item.id, () => {
                  setLoading(false);
                });
              }}
            />
          </Tooltip>
        )}
      </ListItemIcon>
    </ListItem>
  );
};

export default MyLocationsList;
