import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'constant';
import { LocationManagementState } from './slice';
import {
  GetLocationByIdResponseDto,
  PathToLocationInterface,
} from 'interfaces/location-management/ChemicalContent';
import { LOCATION_PERMISSION } from 'enums/permissions.enums';
import { number } from 'prop-types';

export const loadingLocationSelector = createSelector<
  [(state: RootState) => LocationManagementState],
  boolean
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.loadingLocation
);

export const loadingSubstanceSelector = createSelector<
  [(state: RootState) => LocationManagementState],
  boolean
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.loadingSubstance
);

export const reloadSubstanceSelector = createSelector<
  [(state: RootState) => LocationManagementState],
  boolean
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.reloadSubstance
);

export const sectionHeaderTranslationSelector = createSelector<
  [(state: RootState) => LocationManagementState],
  { [key: string]: string } | null
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.sectionHeaderTranslation
);

export const locationData = createSelector<
  [(state: RootState) => LocationManagementState],
  GetLocationByIdResponseDto | null
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.locationData
);

export const locationPath = createSelector<
  [(state: RootState) => LocationManagementState],
  Array<PathToLocationInterface>
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.locationPath
);

export const locationPermissionSelector = createSelector<
  [(state: RootState) => LocationManagementState],
  Array<LOCATION_PERMISSION> | null
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.locationPermission
);

export const hasFailedImportedSDSSelector = createSelector<
  [(state: RootState) => LocationManagementState],
  boolean | null
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.hasFailedImportedSds
);

export const includeSublocationSelector = createSelector<
  [(state: RootState) => LocationManagementState],
  boolean
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.includeSublocation
);

export const openAddSdsPopupSelector = createSelector<
  [(state: RootState) => LocationManagementState],
  boolean
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.openAddSdsPopup
);

export const openAddSdsRequestPopupSelector = createSelector<
  [(state: RootState) => LocationManagementState],
  boolean
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.openAddSdsRequestPopup
);

export const openImportSdsUrlPopupSelector = createSelector<
  [(state: RootState) => LocationManagementState],
  boolean
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.openImportSdsUrlPopup
);

export const printAveryLabelSdsIdsSelector = createSelector<
  [(state: RootState) => LocationManagementState],
  Array<number | string>
>(
  [state => state.locationManagement],
  locationManagement => locationManagement.printAveryLabelSdsIds
);
