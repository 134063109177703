import { createSlice } from '@reduxjs/toolkit';
import { fetchSectionSDSHeaderTranslation } from './thunks';
import {
  GetLocationByIdResponseDto,
  PathToLocationInterface,
} from 'interfaces/location-management/ChemicalContent';
import { editLocation, markFavorite } from './thunks';
import { LOCATION_PERMISSION } from 'enums/permissions.enums';

export interface LocationManagementState {
  loadingLocation: boolean;
  loadingSubstance: boolean;
  reloadSubstance: boolean;
  sectionHeaderTranslation: { [key: string]: string } | null;
  locationData: GetLocationByIdResponseDto | null;
  locationPath: Array<PathToLocationInterface>;
  locationPermission: Array<LOCATION_PERMISSION> | null;
  hasFailedImportedSds: boolean | null;
  includeSublocation: boolean;
  openAddSdsPopup: boolean;
  openAddSdsRequestPopup: boolean;
  openImportSdsUrlPopup: boolean;
  printAveryLabelSdsIds: Array<number | string>;
}

const initialState: LocationManagementState = {
  loadingLocation: false,
  loadingSubstance: false,
  reloadSubstance: false,
  sectionHeaderTranslation: null,
  locationData: null,
  locationPath: [],
  locationPermission: null,
  hasFailedImportedSds: false,
  includeSublocation: false,
  openAddSdsPopup: false,
  openAddSdsRequestPopup: false,
  openImportSdsUrlPopup: false,
  printAveryLabelSdsIds: [],
};

const LocationManagementSlice = createSlice({
  name: 'location-management',
  initialState,
  reducers: {
    resetState(state) {
      state.locationData = initialState.locationData;
      state.locationPermission = initialState.locationPermission;
      state.locationPath = initialState.locationPath;
    },
    setLoadingLocation(state, action: { payload: boolean }) {
      state.loadingLocation = action.payload;
    },
    setLoadingSubstance(state, action: { payload: boolean }) {
      state.loadingSubstance = action.payload;
    },
    setReloadSubstance(state, action: { payload: boolean }) {
      state.reloadSubstance = action.payload;
    },
    setLocationData(state, action: { payload: GetLocationByIdResponseDto }) {
      state.locationData = action.payload;
    },
    setLocationPath(
      state,
      action: { payload: Array<PathToLocationInterface> }
    ) {
      state.locationPath = action.payload;
    },
    setLocationPermission(
      state,
      action: { payload: Array<LOCATION_PERMISSION> | null }
    ) {
      state.locationPermission = action.payload;
    },
    setHasFailedImportedSds(state, action: { payload: boolean | null }) {
      state.hasFailedImportedSds = action.payload;
    },
    setIncludeSublocation(state, action: { payload: boolean }) {
      state.includeSublocation = action.payload;
    },
    setOpenAddSdsPopup(state, action: { payload: boolean }) {
      state.openAddSdsPopup = action.payload;
    },
    setOpenAddSdsRequestPopup(state, action: { payload: boolean }) {
      state.openAddSdsRequestPopup = action.payload;
    },
    setOpenImportSdsUrlPopup(state, action: { payload: boolean }) {
      state.openImportSdsUrlPopup = action.payload;
    },
    setPrintAveryLabelSdsIds(state, action: { payload: Array<number|string> }) {
      state.printAveryLabelSdsIds = action.payload;
    },
    updatePrintAveryLabelSdsIds(state, action: { payload: number|string }) {
      if (state.printAveryLabelSdsIds.indexOf(action.payload) == -1) {
        state.printAveryLabelSdsIds = [ ...state.printAveryLabelSdsIds, action.payload ];
      } else {
        state.printAveryLabelSdsIds = state.printAveryLabelSdsIds.filter(
          (id: number|string) => id != action.payload
        );
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        editLocation.fulfilled,
        (
          state: LocationManagementState,
          action: { payload: GetLocationByIdResponseDto }
        ) => {
          state.locationData = action.payload;
          state.hasFailedImportedSds = action.payload.has_failed_imported_sds;
        }
      )
      .addCase(markFavorite.fulfilled, (state: LocationManagementState) => {
        if (state.locationData) {
          state.locationData = {
            ...state.locationData,
            is_favorite: !state.locationData?.is_favorite,
          };
        }
      })
      .addCase(
        fetchSectionSDSHeaderTranslation.fulfilled,
        (state: LocationManagementState, action: any) => {
          state.sectionHeaderTranslation = action.payload;
        }
      );
  },
});

export const {
  resetState,
  setLoadingLocation,
  setLoadingSubstance,
  setReloadSubstance,
  setLocationData,
  setLocationPath,
  setLocationPermission,
  setHasFailedImportedSds,
  setIncludeSublocation,
  setOpenAddSdsPopup,
  setOpenAddSdsRequestPopup,
  setOpenImportSdsUrlPopup,
  setPrintAveryLabelSdsIds,
  updatePrintAveryLabelSdsIds,
} = LocationManagementSlice.actions;
export default LocationManagementSlice.reducer;
