interface SolutionIdMap {
  [key: string]: string | number;
}

export const SOLUTION_ID_MAPPER: SolutionIdMap = {
  add_locations: '1033',
  access_to_archive_page: '1042',
  archive_location: '1145',
  see_ghs_labels: '1051',
  print_secondary_container_labeling: '1138',
  print_sds_binder: '1065',
  store_amount_on_substance: '1044',
  export_to_xls_sheet: '1130',
  access_to_newer_version_data: '1090',
  upgrade_sds_to_newer: '1090',
  risk_assessment: '1087',
  access_hazardous_substances_page: '1120',
  alert_on_hazardous_substances: '1120',
  access_location_management_page: '1143',
  add_substance: '1081',
  add_sds_from_global_database: '1036',
  add_sds_from_in_use_by_customer: '1142',
  add_attachment: '1121',
  replace_sds: '1139',
  allowed_to_archive_SDS: '1063',
  access_substance_with_missing_sds_page: '1037',
  change_amount_measure: '1044',
  access_to_all_our_sds_page: '1101',
  move_sds: '1133',
  edit_sds: '1107',
  access_to_user_management_page: '1083',
  access_to_import_status_page: '1035',
  allow_to_hide_for_non_admin: '1055',
  access_to_my_locations_page: '1041',
  set_roles_on_location: '1070',
  access_to_qr_code_management_page: '1062',
  allowed_to_shoot_picture: '1122',
  print_qr_code: '1062',
  see_safety_summary: '1146',
  access_to_go_live_checklist_page: '1135',
  reports: '1044',
  log_presence: '1147',
  work_procedure: '1086',
  access_to_my_work_procedure_page: '1086',
  see_custom_fields: '1116',
  // Add later
  // "substitution": "",
  // "exposure": "",
  // "access_to_my_exposure_page": "",
};
