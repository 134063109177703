import {
  useEffect,
  ReactElement,
  useState,
  Suspense,
  lazy,
  Fragment,
} from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { exportSubstances } from 'api';
import { deferImport, shouldShowLocationSubmenuSidebar, shouldShowOnboarding } from 'utils/helper';
import {
  checkUserPermissions,
  isNoAccessRole,
  isStaffRole,
  validateFeatureNotAvailable,
} from 'utils/userRoleUtils';
import { isMobile } from '../../../utils/helper';
import {
  isOwner,
  isAdmin,
  isReadOnlyRole,
  isQRCodeLoginUser,
} from 'utils/userRoleUtils';
import {
  isPinnedSelector,
  expandedItemSelector,
  locationIdSelector,
  expandMenuSelector,
} from 'services/common/selectors';
import {
  setExpandMenu,
  setExpandedItem,
  setOpenDrawer,
} from 'services/common/slice';
import { getMoreSubstancesCount, getNewerSDSCount } from 'services/user/thunks';
import {
  archivedSDSCountSelector,
  missingSDSCountSelector,
  newerCountSelector,
  totalSDSInLocationCountSelector,
  defaultLocationSelector,
  hasImportedBinderSelector,
  userDataSelector,
  accessMyWorkProcedurePageSelector,
  accessMyExposurePageSelector,
  deletedSDSCountSelector,
  completedSDSRequestCountSelector,
} from 'services/user/selectors';
import {
  archivedLocationDataSelector,
  locationDataSelector,
} from 'services/location/selectors';
import { SideBarItem } from 'interfaces/common';
import { ApiResponse } from 'interfaces/common/ApiResponseInterface';
import { PERMISSION } from '../../../enums/permissions.enums';
import { AppDispatch } from 'constant';
/* Icon */
import {
  ThumbUp,
  Home as HomeIcon,
  AccountTree as AccountTreeIcon,
  PinDropOutlined as PinDropOutlinedIcon,
  CircleOutlined as CircleOutlinedIcon,
  LoopOutlined as LoopOutlinedIcon,
  FlareOutlined,
  TravelExplore,
  ImportExport,
  Add,
  Build,
  QrCode2 as QRCodeIcon,
  ArrowDownward,
} from '@mui/icons-material';
import ReportIcon from 'assets/icons/Report';
import RiskIcon from 'assets/icons/Risk';
import MyExposuresIcon from 'assets/icons/MyExposures';
import ComplianceIcon from 'assets/icons/Compiance';
import MyWorkProcedureIcon from 'assets/icons/MyWorkProcedure';
import AllMySdsIcon from 'assets/icons/AllMySds';
import SdsRequestIcon from 'assets/icons/SdsRequest';
/* Components */
import { Popper, List, ClassNameMap, Icon } from '@mui/material';
import MenuItem from './menu-item';
import LocationsListV3 from 'components/locations-list/LocationsListV3';
import LoadingModal from '../../loader/LoadingModal';
import CollapseListItem from './collapse-list-item';
import CustomLoader from 'components/loader/Loader';
import CustomBadge from './badge';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import SdsRequestImage from 'icons/sds.png';
import StepCircularProgress from 'components/onboarding/step-circular-progress';
import useSetUpStep from 'hooks/useSetUpStep';

/* Lazy load component */
const CreateLocationPopup = lazy((): Promise<any> => {
  return deferImport(
    import('components/popup/create-location-popup/CreateLocationPopup')
  );
});

const ManualAddSubstancePopupV2 = lazy((): Promise<any> => {
  return deferImport(
    import('components/location-management/chemicals-on-location-content-v2/components/popup/manual-add-substance-popup-v2')
  );
});
/* End */

interface CustomSidebarParam {
  selectedUserId: string;
}

const CustomSidebar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const locationPath = useLocation();
  const classes: ClassNameMap = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const param = useParams<CustomSidebarParam>();
  /* Selector */
  const isPinned = useSelector(isPinnedSelector);
  const expandedItem = useSelector(expandedItemSelector);
  const defaultLocationID = useSelector(defaultLocationSelector);
  const showMenu = useSelector(expandMenuSelector);
  const user = useSelector(userDataSelector);
  const allLocations = useSelector(locationDataSelector);
  const newerSDSCount = useSelector(newerCountSelector);
  const missingSDSCount = useSelector(missingSDSCountSelector);
  const completedSDSRequestCount = useSelector(
    completedSDSRequestCountSelector
  );
  const archivedSDSCount = useSelector(archivedSDSCountSelector);
  const deletedSDSCount = useSelector(deletedSDSCountSelector);
  const totalSDSInLocationCount = useSelector(totalSDSInLocationCountSelector);
  const locationID = useSelector(locationIdSelector);
  const hasImportedBinder = useSelector(hasImportedBinderSelector);
  const accessMyWorkProcedurePage = useSelector(
    accessMyWorkProcedurePageSelector
  );
  const accessMyExposurePage = useSelector(accessMyExposurePageSelector);
  const archivedLocations = useSelector(archivedLocationDataSelector);
  const showMore = useSelector(expandMenuSelector);
  /* State */
  const [anchorEl, setAnchorEl] = useState<HTMLElement | any>(null);
  const [error, setError] = useState<boolean>(false);
  const [loadModal, setLoadModal] = useState<boolean>(false);
  const [openCreateLocationPopup, setOpenCreateLocationPopup] =
    useState<ReactElement | null>(null);
  const [openclassName, setOpenClassName] = useState<string>('');
  const [openAddSdsRequestPopup, setOpenAddSDSRequestPopup] =
    useState<boolean>(false);
  /* Const */
  const isAdminOrOwner = isOwner(user) || isAdmin(user);
  const path: string = window.location.pathname;
  const isQRLogin: boolean = isQRCodeLoginUser(user);
  const iconSize: number = 24;
  const isOpenImportedSds: string | null = user
    ? localStorage.getItem(`is_open_imported_sds_${user.id}`)
    : null;
  const openImportedSdsHelpText: boolean =
    (user?.customer?.inventory_subscription_plan?.plan?.plan as string) ==
      'Demo Inv Mgr' &&
    !isOpenImportedSds &&
    window.location.pathname == '/' &&
    !error;

  const showLocationMenuItem = (): boolean => {
    if (user?.customer?.has_multi_location) return true;
    if (allLocations.length === 0) return false;
    if (allLocations.length > 1) return true;
    return allLocations[0].children?.length > 0;
  };

  const isSelected = (value: string): boolean => {
    if (!locationPath || !locationPath.pathname) return false;
    if (value === 'substances') {
      const substanceTest =
        /.*\/(all-our-sds|sds-with-newer-version|hazardous-substances).*/;
      return substanceTest.test(locationPath.pathname);
    }
    if (value === 'user-management') {
      const userMntChildPage = `/administration/user-management/user/${param.selectedUserId}`;
      return userMntChildPage == locationPath.pathname;
    }
    if (value === 'manage_qr_codes') {
      const regexTest = /\/administration\/qr-code-management(\/)*/;
      return regexTest.test(locationPath.pathname);
    }
    if (value === 'location-management') {
      const regexTest =
        /.*\/(location-management|unmatched-substances|archived).*/;
      return regexTest.test(locationPath.pathname);
    }
    const selectedTest = new RegExp(`.*\/${value}.*`, 'g');
    return selectedTest.test(locationPath.pathname);
  };

  const handleExportLibrary = (): void => {
    setLoadModal(true);
    exportSubstances().then((response: ApiResponse<string>) => {
      if (response.status === 200) {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type: type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'sds-library.csv';
        link.click();
        setLoadModal(false);
      }
    });
  };

  const { setUpStepProgress, hasCompletedAll } = useSetUpStep();

  /* Substance Submenu List */
  const substanceItems: Array<SideBarItem> = [
    {
      value: 'all-our-sds',
      translation: 'sidebar:all_our_sds',
      icon: <CircleOutlinedIcon style={{ fontSize: 10 }} />,
      onClick: () => {
        if (!isPinned) closeAllTabs();
        onSingleItemClick('all-our-sds', '/all-our-sds/');
      },
      permission: PERMISSION.ACCESS_TO_ALL_OUR_SDS_PAGE,
    },
    {
      value: 'sds-with-newer-version',
      translation: 'sidebar:has_newer_version',
      icon: <CircleOutlinedIcon style={{ fontSize: 10 }} />,
      badge: <CustomBadge value={newerSDSCount} isChild={true} />,
      onClick: () => {
        if (!isPinned) closeAllTabs();
        onSingleItemClick('sds-with-newer-version', '/sds-with-newer-version/');
      },
      permission: PERMISSION.ACCESS_TO_NEWER_VERSION_DATA,
      hide: (!isOwner(user) && !isAdmin(user)) || newerSDSCount < 1,
    },
    {
      value: 'hazardous-substances',
      translation: 'sidebar:hazardous_substances',
      icon: <CircleOutlinedIcon style={{ fontSize: 10 }} />,
      onClick: () => {
        if (!isPinned) closeAllTabs();
        onSingleItemClick('hazardous-substances', '/hazardous-substances/');
      },
      permission: PERMISSION.ACCESS_HAZARDOUS_SUBSTANCES_PAGE,
    },
  ];

  /* Report Submenu list */
  const reportItems: Array<SideBarItem> = [
    {
      value: 'reports',
      translation: 'sidebar:use_disposal',
      icon: <CircleOutlinedIcon style={{ fontSize: 10 }} />,
      onClick: () => onSingleItemClick('reports', '/reports'),
      permission: PERMISSION.ACCESS_TO_USE_DISPOSAL_PAGE,
      hide: isQRLogin,
    },
    {
      value: 'export-substances',
      translation: 'sidebar:export_substances',
      icon: <CircleOutlinedIcon style={{ fontSize: 10 }} />,
      onClick: () => handleExportLibrary(),
      hide: !isAdminOrOwner,
    },
  ];

  const locationManagementItem: Array<SideBarItem> = [
    {
      value: 'add-location',
      translation: 'common:add_location',
      icon: <Add className={classes.iconWithBackground} />,
      onClick: () =>
        setOpenCreateLocationPopup(
          <Suspense fallback={<CustomLoader />}>
            <CreateLocationPopup
              onClose={() => setOpenCreateLocationPopup(null)}
              open={true}
              parentDepartmentID={locationID}
            />
          </Suspense>
        ),
      permission: PERMISSION.ADD_LOCATIONS,
    },
    {
      value: 'import-status',
      translation: 'sidebar:import_status',
      icon: <ImportExport className={classes.iconWithBackground} />,
      onClick: () => {
        if (!isPinned) closeAllTabs();
        onSingleItemClick(
          'import-status',
          '/location-management/import-status/'
        );
      },
      permission: PERMISSION.ACCESS_TO_IMPORT_STATUS_PAGE,
      hide: !hasImportedBinder,
    },
    {
      value: 'unmatched-substances',
      translation: 'sidebar:sds_requets',
      icon: (
        <SdsRequestIcon
          width={10}
          height={10}
          className={classes.iconWithBackground}
          style={{
            position: 'relative',
            fontSize: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      ),
      badge: !checkUserPermissions(
        PERMISSION.ACCESS_SUBSTANCE_WITH_MISSING_SDS_PAGE,
        true
      ) ? null : (
        <CustomBadge value={missingSDSCount} />
      ),
      onClick: () => {
        if (!isPinned) closeAllTabs();
        onSingleItemClick('unmatched-substances', '/unmatched-substances/');
      },
      permission: PERMISSION.ACCESS_SUBSTANCE_WITH_MISSING_SDS_PAGE,
      hide: missingSDSCount < 1 && completedSDSRequestCount < 1,
    },
    {
      value: 'archive',
      translation: 'sidebar:archive',
      icon: <ArrowDownward className={classes.iconWithBackground} />,
      onClick: () => {
        if (!isPinned) closeAllTabs();
        onSingleItemClick('archived', '/archived/');
      },
      permission: PERMISSION.ACCESS_TO_ARCHIVE_PAGE,
      hide:
        archivedSDSCount < 1 &&
        deletedSDSCount < 1 &&
        (!checkUserPermissions(
          PERMISSION.CAN_SEE_FILTER_CHECKBOXES_ON_LOCATION
        ) ||
          !archivedLocations ||
          archivedLocations.length == 0),
    },
  ];

  const sidebarItems: Array<SideBarItem> = [
    {
      value: 'dashboard',
      translation: 'sidebar:home',
      icon: <HomeIcon style={{ fontSize: iconSize }} />,
      onClick: () => {
        if (!showMenu) dispatch(setExpandMenu(true));
        closeAllTabs();
        onSingleItemClick('dashboard', '/dashboard');
      },
      hide: isQRLogin
    },
    {
      value: 'my-sds',
      translation: 'common:all_my_sds',
      icon: <AllMySdsIcon width={iconSize} height={iconSize} />,
      childrenComponent: (
        <div style={{ width: '100%' }}>
          <LocationsListV3 admin={false} />
        </div>
      ),
      hasChildren: true,
      onClick: (value = 'my-sds') => {
        if (!showMenu) dispatch(setExpandMenu(true));
        dispatch(setExpandedItem(value === expandedItem ? null : value));
        if (!shouldShowLocationSubmenuSidebar(path)) {
          dispatch(setOpenDrawer(false));
          history.push('/my-sds');
        }
      },
      hide: checkUserPermissions(PERMISSION.ACCESS_LOCATION_MANAGEMENT_PAGE),
    },
    {
      value: 'my-sds',
      translation: 'common:all_my_sds',
      icon: <AllMySdsIcon width={iconSize} height={iconSize} />,
      onClick: () => {
        closeAllTabs();
        onSingleItemClick('my-sds', '/my-sds');
      },
      permission: PERMISSION.ACCESS_LOCATION_MANAGEMENT_PAGE,
    },
    {
      value: 'locations',
      translation: 'sidebar:my_locations',
      onClick: () => {
        closeAllTabs();
        onSingleItemClick('locations', '/locations');
      },
      icon: <PinDropOutlinedIcon style={{ fontSize: iconSize }} />,
      permission: PERMISSION.ACCESS_TO_MY_LOCATIONS_PAGE,
      hide: !showLocationMenuItem(),
    },
    {
      value: 'location-management',
      translation: 'sidebar:manage_sds_library',
      icon: (
        <Build
          style={{
            fontSize: iconSize,
            rotate: '90deg',
          }}
        />
      ),
      badge: <CustomBadge value={totalSDSInLocationCount} color="primary" />,
      childrenComponent: (
        <div style={{ width: '100%' }}>
          <LocationsListV3 admin={isAdminOrOwner} />
          <CollapseListItem
            listItem={locationManagementItem}
            isSelected={isSelected}
            style={{
              paddingLeft: '20px',
              position: 'sticky',
              bottom: 0,
              backgroundColor: '#ffffff',
            }}
          />
        </div>
      ),
      hasChildren: true,
      onClick: (value = 'location-management') => {
        if (!showMenu) dispatch(setExpandMenu(true));
        dispatch(setExpandedItem(value === expandedItem ? null : value));
        if (!shouldShowLocationSubmenuSidebar(path)) {
          dispatch(setOpenDrawer(false));
          if (defaultLocationID) {
            history.push(
              `/location-management/location-detail/${defaultLocationID}`
            );
          } else {
            history.push(`/location-management/`);
          }
        }
      },
      helpComponent: (
        <div
          className={classes.poperContent}
          onClick={() => {
            if (user?.id) {
              localStorage.setItem(
                `is_open_imported_sds_${user.id}`,
                String(true)
              );
              setError(true);
            }
          }}
        >
          <span> {t('sidebar:see_your_imported_sds_here')} </span>
          <ThumbUp />
        </div>
      ),
      permission: PERMISSION.ACCESS_LOCATION_MANAGEMENT_PAGE,
    },
    {
      value: 'global-sds-search',
      translation: 'sidebar:global_sds_search',
      icon: <TravelExplore width={iconSize} height={iconSize} />,
      onClick: () => {
        closeAllTabs();
        onSingleItemClick('global-sds-search', '/global-sds-search');
      },
      permission: PERMISSION.ALLOWED_TO_DO_GLOBAL_SEARCH,
    },
    {
      value: 'substances',
      translation: 'sidebar:sds_complience',
      childrenComponent: (
        <CollapseListItem listItem={substanceItems} isSelected={isSelected} />
      ),
      icon: <ComplianceIcon fontSize={iconSize} />,
      hasChildren: true,
      onClick: (value = 'substances') => {
        if (!showMenu) dispatch(setExpandMenu(true));
        dispatch(setExpandedItem(value === expandedItem ? null : value));
        if (value !== expandedItem) {
          dispatch(getNewerSDSCount());
          dispatch(getMoreSubstancesCount());
          setTimeout(() => dispatch(setExpandedItem(value)), 250);
        } else dispatch(setExpandedItem(null));
      },
      badge: !checkUserPermissions(
        PERMISSION.ACCESS_TO_NEWER_VERSION_DATA,
        true
      ) ? null : (
        <CustomBadge value={newerSDSCount} />
      ),
      hide: isReadOnlyRole(user) || isNoAccessRole(user) || isStaffRole(user),
    },
    {
      value: 'manage_qr_codes',
      translation: 'sidebar:manage_qr_codes',
      icon: <QRCodeIcon width={iconSize} height={iconSize} />,
      onClick: () => {
        closeAllTabs();
        onSingleItemClick(
          'manage_qr_codes',
          '/administration/qr-code-management'
        );
      },
      permission: PERMISSION.ACCESS_TO_QR_CODE_MANAGEMENT_PAGE,
      hide: !isAdminOrOwner,
    },
    {
      value: 'substitutions',
      translation: 'sidebar:substitutions',
      icon: <LoopOutlinedIcon width={iconSize} height={iconSize} />,
      onClick: () => {
        closeAllTabs();
        onSingleItemClick('substitutions', '/substitutions');
      },
      permission: PERMISSION.SUBSTITUTION,
    },
    {
      value: 'risk-assessments',
      translation: 'sidebar:risk_assessment',
      icon: <RiskIcon width={iconSize} height={iconSize} />,
      onClick: () => {
        closeAllTabs();
        onSingleItemClick('risk-assessments', '/risk-assessments');
      },
      permission: PERMISSION.RISK_ASSESSMENT,
      hide: isReadOnlyRole(user) || isNoAccessRole(user),
    },
    {
      value: 'work-procedures',
      translation: 'common:job_procedures',
      icon: <AccountTreeIcon style={{ fontSize: iconSize }} />,
      onClick: () => {
        closeAllTabs();
        onSingleItemClick('work-procedures', '/work-procedures', false);
      },
      permission: PERMISSION.WORK_PROCEDURE,
      hide: isReadOnlyRole(user) || isNoAccessRole(user),
    },
    {
      value: 'my-work-procedures',
      translation: 'sidebar:my_work_procedures',
      onClick: () => {
        closeAllTabs();
        onSingleItemClick('my-work-procedures', '/my-work-procedures');
      },
      icon: <MyWorkProcedureIcon fontSize={iconSize} />,
      permission: PERMISSION.ACCESS_TO_MY_WORK_PROCEDURE_PAGE,
      hide: !accessMyWorkProcedurePage,
    },
    {
      value: 'exposures',
      translation: 'sidebar:exposures',
      icon: <FlareOutlined width={iconSize} height={iconSize} />,
      onClick: () => {
        closeAllTabs();
        onSingleItemClick('exposures', '/exposures/', false);
      },
      permission: PERMISSION.EXPOSURE,
    },
    {
      value: 'my-exposures',
      translation: 'sidebar:my_exposures',
      icon: <MyExposuresIcon fontSize={iconSize} />,
      onClick: () => {
        closeAllTabs();
        onSingleItemClick('exposures', '/my-exposures/', false);
      },
      permission: PERMISSION.ACCESS_TO_MY_EXPOSURE_PAGE,
      hide: !accessMyExposurePage,
    },
    {
      value: 'reports',
      translation: 'sidebar:reports',
      icon: <ReportIcon fontSize={iconSize} marginRight={'3px'} />,
      onClick: (value = 'reports') => {
        if (!showMenu) dispatch(setExpandMenu(true));
        dispatch(setExpandedItem(value === expandedItem ? null : value));
      },
      childrenComponent: (
        <CollapseListItem listItem={reportItems} isSelected={isSelected} />
      ),
      hasChildren: true,
      permission: PERMISSION.REPORTS,
      hide: isQRLogin,
    },
    {
      value: 'qr_user_sds_request',
      translation: 'sidebar:sds_requets',
      icon: (
        <Icon
          style={{
            position: 'relative',
            fontSize: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 28,
            height: 28
          }}
        >
          <img 
            alt="Icon" 
            src={SdsRequestImage} 
            style={{
              width: '100%',
              height: '100%',
              objectFit:'contain'
            }}
          />
        </Icon>
      ),
      onClick: () => {
        setOpenAddSDSRequestPopup(true)
      },
      hide: !isQRLogin || !user?.customer.allow_employees_to_request_sds,
    },
  ];

  const bottomSidebarItems: Array<SideBarItem> = [
    {
      value: 'onboarding',
      translation: 'onboarding_page:get_set_up',
      icon: (
        <StepCircularProgress value={setUpStepProgress} size={20} />
      ),
      onClick: () => {
        if (!showMenu) dispatch(setExpandMenu(true));
        closeAllTabs();
        onSingleItemClick('onboarding', '/onboarding');
      },
      hide: !shouldShowOnboarding(user, 'page') || hasCompletedAll,
    },
  ];


  const closeAllTabs = (): void => {
    dispatch(setExpandedItem(null));
  };

  const onSingleItemClick = (
    value: string,
    target: string,
    disabledWhenSelected: boolean = true
  ): void => {
    if (path === target || path === target + '/') return;
    if (isSelected(value) && disabledWhenSelected) return;
    dispatch(setOpenDrawer(false));
    history.push(target);
    if (!isPinned && value !== 'dashboard') dispatch(setExpandMenu(false));
    return;
  };

  const renderSidebarItem = (item: SideBarItem): ReactElement | null => {
    if (item.hide || !user) return null;
    if (item.permission && !checkUserPermissions(item.permission, true))
      return null;

    if (item.hasChildren) {
      return (
        <List
          component="div"
          key={item.value}
          className={classes.iconWrapperMore}
        >
          <MenuItem
            content={item.translation}
            isSelected={isSelected}
            value={item.value}
            hasChildren={item.hasChildren}
            childrenComponent={item.childrenComponent}
            itemIcon={item.icon}
            isExpanded={expandedItem === item.value}
            onClick={() => {
              if (
                item.permission &&
                validateFeatureNotAvailable(item.permission)
              ) {
                dispatch(setOpenDrawer(false));
                return;
              }

              item.onClick !== undefined && item.onClick();
            }}
            badge={item.badge}
            showMore={showMore}
          />
        </List>
      );
    }

    return (
      <Fragment key={item.value}>
        <List
          component="div"
          id={item.helpComponent ? 'imported_sds_popper' : item.value}
          key={item.value}
          onClick={() => {
            if (item.helpComponent) {
              if (user?.id) {
                localStorage.setItem(
                  `is_open_imported_sds_${user.id}`,
                  String(true)
                );
                setError(true);
              }
            }
            if (item.value !== 'qr_user_sds_request') {
              dispatch(setOpenDrawer(false));
            }
          }}
          className={classes.iconWrapperMore}
        >
          <MenuItem
            content={item.translation}
            isSelected={isSelected}
            value={item.value}
            hasChildren={item.hasChildren}
            childrenComponent={item.childrenComponent}
            itemIcon={item.icon}
            isExpanded={item.isExpanded}
            onClick={() => {
              if (
                item.permission &&
                validateFeatureNotAvailable(item.permission)
              )
                return;

              item.onClick !== undefined && item.onClick();
            }}
            badge={item.badge}
            showMore={showMore}
          />
        </List>

        {item.helpComponent && (
          <Popper
            open={openImportedSdsHelpText}
            anchorEl={anchorEl}
            placement="right"
            className={classes.popper}
          >
            {item.helpComponent}
          </Popper>
        )}
      </Fragment>
    );
  };

  useEffect(() => {
    const locationName: string = history.location.pathname;
    if (locationName === '/') {
      dispatch(setExpandMenu(true));
      closeAllTabs();
      return;
    }
  }, []);

  useEffect(() => {
    const e: HTMLElement | null = document.getElementById(
      'imported_sds_popper'
    );
    if (e) setAnchorEl(e);
    else setError(true);
    if (!showMenu) closeAllTabs();
  }, [showMenu]);

  useEffect(() => {
    if (history.location.search.includes('substances')) {
      dispatch(setExpandMenu(true));
      dispatch(setExpandedItem('substances'));
      return;
    }
    if (history.location.search.includes('administration')) {
      dispatch(setExpandMenu(true));
      dispatch(setExpandedItem('administration'));
      return;
    }
    if (history.location.search.includes('location-management')) {
      dispatch(setExpandMenu(true));
      dispatch(setExpandedItem('location-management'));
      return;
    }

    const showLocationList: boolean =
      shouldShowLocationSubmenuSidebar(path) && showMenu;

    if (showLocationList && !expandedItem) {
      setTimeout(() => {
        const selectedexpandedItem = checkUserPermissions(
          PERMISSION.ACCESS_LOCATION_MANAGEMENT_PAGE
        )
          ? 'location-management'
          : 'my-sds';
        dispatch(setExpandedItem(selectedexpandedItem));
      }, 250);
    }
  }, [history.location.pathname, showMenu]);

  useEffect(() => {
    if (showMore) {
      setOpenClassName(classes.open);
      setTimeout(
        () => setOpenClassName(`${classes.open} ${classes.openSidebarTex}`),
        250
      );
    }
  }, [showMore]);

  return (
    <>
      <div
        className={
          !isMobile()
            ? `${classes.sidebar} ${showMore ? openclassName : null}`
            : undefined
        }
      >
        <SimpleBar className={classes.iconsWrapperRoot}>
          <div className={classes.sidebarIconsMoreWrapper}>
            {isMobile() && (
              <Fragment>
                <img
                  alt="Icon"
                  src={'/images/logo.svg'}
                  className={classes.logoImgMobile}
                />
              </Fragment>
            )}
            {sidebarItems.map((item: SideBarItem) => renderSidebarItem(item))}
          </div>
          <div className={classes.sidebarBottom}>
            {bottomSidebarItems.map((item: SideBarItem) => (
              renderSidebarItem(item)
            ))}
          </div>
        </SimpleBar>
      </div>
      {loadModal && <LoadingModal />}
      <Suspense>
        {openCreateLocationPopup}
        {openAddSdsRequestPopup && (
          <ManualAddSubstancePopupV2
            onClose={() => setOpenAddSDSRequestPopup(false)}
            isReadOnlyUser={true}
          />
        )}
      </Suspense>
    </>
  );
};

export default CustomSidebar;
