import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: '14px',
  },
  /* AllLocation */
  allLocationContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(58, 53, 65, 0.87)',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  allLocationHeaderContainer: {
    display: 'flex',
    margin: '20px 0px',
    gap: 20,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  allLocationTreeContainer: {
    fontSize: '0.9rem',
    '& .MuiTreeItem-label': {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  allLocationSearch: {
    flex: 1,
  },
  locationSearchBlock: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  locationFont: {
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  treeHeaderContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '161px',
    marginBottom: '4px',
  },
  adminUserColumnHeader: {
    width: '207px',
    textAlign: 'center',
  },
  /* */
  listItemRoot: {
    padding: '10px 9px 10px 8px',
    display: 'flex',
    '&.Mui-selected': {
      backgroundColor: '#ECF7FF',
      color: '#626DF9',
      '& .svg-icon': {
        fill: '#626DF9',
      },
      '& .MuiSvgIcon-root': {
        fill: '#626DF9',
      },
    },
    '&:hover': {
      background: '#ECF7FF!important',
      color: '#626DF9!important',
      '& .svg-icon': {
        fill: '#626DF9!important',
      },
      '& .MuiSvgIcon-root': {
        fill: '#626DF9',
        visibility: 'visible!important',
      },
    },
  },
  childMenuItem: {
    padding: '10px 9px 10px 8px!important',
    '&:hover': {
      background: '#ECF7FF!important',
      color: '#626DF9',
      '& .svg-icon': {
        fill: '#626DF9',
      },
      '& .MuiSvgIcon-root': {
        visibility: 'visible!important',
      },
    },
  },
  selectedRow: {
    background: '#ECF7FF',
  },
  locWrapper: {
    marginLeft: '5%',
    marginBottom: '18%',
  },
  locations: {
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(58, 53, 65, 0.87)',
    paddingLeft: 24,
  },
  locationList: {
    fontSize: '0.9rem',
    // [theme.breakpoints.down('sm')]: {
    //   width: '95%',
    //   maxHeight: '130px',
    //   overflow: 'auto',
    //   scrollbarColor: 'transparent transparent',
    //   marginBottom: '10px',
    // },
    '& .MuiTreeItem-label': {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  locationInfo: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '72%',
  },
  favoriteLocationList: {
    fontSize: '0.9rem',
    marginBottom: 20,
    // [theme.breakpoints.down('sm')]: {
    //   width: '95%',
    //   maxHeight: '700px',
    //   overflow: 'auto',
    //   scrollbarColor: 'transparent transparent',
    //   marginBottom: '10px',
    // },
  },
  locationNotDefaultIcon: {
    visibility: 'hidden',
  },
  locationItem: {},
  locationItemFont: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '24px',
    display: 'flex',
  },
  myLocationList: {
    padding: 0,
    // [theme.breakpoints.down('sm')]: {
    //   overflow: 'auto',
    //   scrollbarColor: 'transparent transparent',
    //   marginBottom: '10px',
    // },
  },
  accordion: {
    width: '95%',
    paddingLeft: '1%',
    paddingRight: '0 !important',
    marginBottom: 10,
  },
  countSds: {
    marginLeft: 5,
  },
  sdsLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
    // display: 'flex',
  },
  submitBtn: {
    backgroundColor: '#626DF9',
    width: '200px',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgb(98, 109, 249,0.5)',
    },
  },
  locationItemIcon: {
    // '&:hover': {
    //   background: '#ECF7FF!important',
    //   color: '#626DF9!important',
    //   '& .svg-icon': {
    //     fill: '#626DF9!important',
    //   },
    //   '& .MuiSvgIcon-root': {
    //     fill: '#626DF9',
    //     visibility: 'visible!important',
    //   },
    // },
  },
  showArchivedLocationsBlock: {
    display: 'flex',
    gap: 12,
    marginTop: 10,
    padding: '10px 5px',
    fontSize: 13,
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover': {
      color: '#626DF9!important',
      backgroundColor: '#ECF7FF',
    },
    '& svg': {
      width: 14.67,
      height: 14.67,
      padding: 1,
      fill: '#FFF',
      backgroundColor: '#626DF9',
      borderRadius: '50%',
    },
  },
  archivedLocationListContainer: {
    paddingLeft: '0',
    overflow: 'auto',
    height: '100%',
  },
}));

export default useStyles;
